<template>
    <div class="component-hamburger">
        <button
            class="hamburger navbar-toggler"
            type="button"
            :class="{ 'is-active': isExpanded }"
            @click="onToggle"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="hamburger-box">
                <span class="hamburger-inner" />
            </span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'NavBar',
        props: {
            isExpanded: Boolean,
        },
        methods: {
            onToggle: function () {
                this.$logEvent('click_hamburger_menu')
                this.$emit('click')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/hamburger.scss';
</style>

<template>
    <div
        class="component-navbar"
        :class="navContainerClasses"
    >
        <div class="nav-container container">
            <nav class="nav">
                <div class="left">
                    <div :class="{ 'dark-logo': isDarkLogo }">
                        <a
                            class="brand"
                            href="/"
                            aria-label="Aven"
                        >
                            <logo
                                class="logo"
                                width="172"
                                :fill="isDarkLogo ? 'white' : 'black'"
                            />
                        </a>
                        <span
                            v-if="isDarkLogo"
                            class="logo-subtext"
                        >Home Equity<br>
                            Cashback Rewards
                        </span>
                    </div>
                </div>

                <div class="middle">
                    <ul v-if="!showCTA">
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'landing' }"
                                @click.native="handleSendEvent('landing')"
                                :to="{ name: 'landing' }"
                            >
                                {{ $t('components.navbarMarketing.home') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'education' }"
                                @click.native="handleSendEvent('education')"
                                :to="{ name: 'education' }"
                            >
                                {{ $t('components.navbarMarketing.education') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'reviews' }"
                                @click.native="handleSendEvent('reviews')"
                                :to="{ name: 'reviews' }"
                            >
                                {{ $t('components.navbarMarketing.reviews') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'support_home' }"
                                @click.native="handleSendEvent('support_home')"
                                :to="{ name: 'support_home' }"
                            >
                                {{ $t('components.navbarMarketing.support') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'app' }"
                                @click.native="handleSendEvent('app')"
                                :to="{ name: 'app' }"
                            >
                                {{ $t('components.navbarMarketing.app') }}
                            </router-link>
                        </li>
                        <li class="has-dropdown">
                            <router-link
                                class="nav-item"
                                :class="{ active: $router.currentRoute.name === 'whoWeAre' }"
                                @click.native="handleSendEvent('whoWeAre')"
                                :to="{ name: 'about' }"
                            >
                                {{ $t('components.navbarMarketing.whoWeAre') }}
                            </router-link>
                            <div
                                class="dropdown"
                                aria-labelledby="navbarDropdown"
                            >
                                <router-link
                                    :to="{ name: 'about' }"
                                    @click.native="handleSendEvent('about')"
                                >
                                    {{ $t('components.navbarMarketing.about') }}
                                </router-link>
                                <router-link
                                    :to="{ name: 'contact' }"
                                    @click.native="handleSendEvent('contact')"
                                >
                                    {{ $t('components.navbarMarketing.contact') }}
                                </router-link>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="right">
                    <hamburger
                        v-if="!showCTA"
                        :is-expanded="isExpanded"
                        @click="toggleMenu"
                        class="d-block d-lg-none"
                    />

                    <ul
                        v-if="!showCTA"
                        class="d-none d-lg-block"
                    >
                        <li v-if="isLoggedIn">
                            <a
                                class="nav-item"
                                :href="signInPagePath"
                            >
                                {{ $t('components.navbarMarketing.dashboard') }}
                            </a>
                        </li>
                        <li v-else>
                            <a
                                class="nav-item"
                                :href="signInPagePath"
                            >
                                {{ $t('components.navbarMarketing.signin') }}
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>

            <div class="mobile-nav">
                <ul>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'landing' }"
                            @click.native="toggleMenu('landing')"
                        >
                            {{ $t('components.navbarMarketing.home') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'education' }"
                            @click.native="toggleMenu('education')"
                        >
                            {{ $t('components.navbarMarketing.education') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'reviews' }"
                            @click.native="toggleMenu('reviews')"
                        >
                            {{ $t('components.navbarMarketing.reviews') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'support_home' }"
                            @click.native="toggleMenu('support')"
                        >
                            {{ $t('components.navbarMarketing.support') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'app' }"
                            @click.native="toggleMenu('app')"
                        >
                            {{ $t('components.navbarMarketing.app') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'about' }"
                            @click.native="toggleMenu('about')"
                        >
                            {{ $t('components.navbarMarketing.about') }}
                        </router-link>
                    </li>
                    <li>
                        <router-link
                            class="nav-item"
                            :to="{ name: 'contact' }"
                            @click.native="toggleMenu('contact')"
                        >
                            {{ $t('components.navbarMarketing.contact') }}
                        </router-link>
                    </li>
                    <li v-if="isLoggedIn">
                        <a
                            class="nav-item"
                            :href="signInPagePath"
                        >
                            {{ $t('components.navbarMarketing.dashboard') }}
                        </a>
                    </li>
                    <li v-else>
                        <a
                            class="nav-item"
                            :href="signInPagePath"
                        >
                            {{ $t('components.navbarMarketing.signin') }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <nav class="nav sticky">
            <div class="container d-flex">
                <div class="left">
                    <a
                        class="brand"
                        href="/"
                    >
                        <img
                            src="../assets/images/components/navbar/aven.svg"
                            class="logo"
                            width="71"
                            alt="Go Home"
                        >
                    </a>
                </div>
                <div class="right">
                    <li>
                        <button
                            class="btn nav-link text-white fw-bold bg-dark rounded-pill ps-3 pe-3 ms-3"
                            @click="scrollToPhoneInput"
                        >
                            {{ $t('components.navbarMarketing.checkOffers') }}
                        </button>
                    </li>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
    import Hamburger from './Hamburger'
    import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
    import { experimentsMixin } from '@/mixins/experimentsMixin'
    import Logo from '@/components/Logo'

    export default {
        name: 'NavBar',
        components: {
            hamburger: Hamburger,
            logo: Logo,
        },
        mixins: [experimentsMixin],
        data() {
            return {
                isExpanded: false,
                resourceMenuExpanded: false,
                scrolled: false,
                showCTA: false,
                isLoggedIn: false,
                signInPagePath: process.env.VUE_APP_MY_AVEN_URL,
            }
        },
        created() {
            this.addScrollListener()
            this.isLoggedIn = !!appSessionStorage.getItem(sessionStorageKey.jwtTokens)
        },
        computed: {
            navContainerClasses: function () {
                return {
                    expanded: this.isExpanded,
                    scrolled: this.scrolled,
                }
            },
            isDarkLogo() {
                return false
            },
        },
        methods: {
            handleSendEvent(pageName) {
                this.$logEvent('click_navigation_item', { pageName })
            },
            toggleMenu(pageName) {
                this.$logEvent('click_navigation_item', { pageName })
                this.isExpanded ? this.addScrollListener() : this.removeScrollListener()
                this.isExpanded = !this.isExpanded
                this.handleScroll()
            },
            toggleResourceMenu() {
                this.resourceMenuExpanded = !this.resourceMenuExpanded
            },
            handleScroll() {
                this.scrolled = window.scrollY > 800

                const showCta = ['home', 'auto']
                if (showCta.includes(this.$route.name)) {
                    this.showCTA = window.scrollY > 700
                }
            },
            addScrollListener() {
                window.addEventListener('scroll', this.handleScroll)
            },
            removeScrollListener() {
                window.removeEventListener('scroll', this.handleScroll)
            },
            scrollToPhoneInput: async function () {
                const phoneElem = document.getElementById('phone')
                if (!phoneElem) {
                    await this.$router.push({ path: '/' })
                    return
                }
                window.scrollTo(0, 0)
                phoneElem.focus()
                this.isExpanded = false
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/navbar';
</style>
